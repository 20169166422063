import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject } from 'rxjs'; 
import { PlatformAdminService } from 'src/app/services/platform-admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as errorUtils from "src/app/modules/utility/global-utils";
import * as RolesRouteMap from '../../../data/constants/roles';
import { CompanyService } from 'src/app/services/company.service';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { BulkUploadDashboardService } from 'bulk-upload-dashboard';
import { environment } from 'environment-configurations';

@Component({
  selector: 'app-download-reports',
  templateUrl: './download-reports.component.html',
  styleUrls: ['./download-reports.component.scss']
})
export class DownloadReportsComponent implements OnInit, OnDestroy {
  downloadReportsForm: FormGroup;
  observableSubscription$ = new Subject();
  listOfCompanies: any = [];
  maxFromDate = new Date();
  minToDate = null;
  constructor(private platformAdminService: PlatformAdminService,private fb: FormBuilder,private router: Router,private companyService: CompanyService, private bulkUploadDashboardService: BulkUploadDashboardService) { 
  }

  ngOnInit(): void {
    this.downloadReportsForm = this.fb.group({
      companies: [null, Validators.required],
      fromDate: [null, Validators.required],
      toDate: [null, Validators.required]
    });
    this.getCompanies();
  }

  getCompanies(){
    const requestArray = [this.companyService.getCompaniesPlatformAdmin('PETNR'),this.companyService.getCompaniesPlatformAdmin('ATRNY')];
    forkJoin(requestArray).pipe(takeUntil(this.observableSubscription$)).subscribe((results) => {
      this.listOfCompanies = [...results[0]?.filter(x => x.companyName),...results[1]?.filter(x => x.companyName)];
    });
  }

  setMinToDate(value: any){
    this.minToDate = new Date(value);
  }

  downloadReport(): void {
    let payload = { dateFrom: this.downloadReportsForm.value?.fromDate ? moment(this.downloadReportsForm.value?.fromDate).utc(true).format('YYYY-MM-DD') : null,dateTo: this.downloadReportsForm.value?.toDate ? moment(this.downloadReportsForm.value?.toDate).utc(true).format('YYYY-MM-DD') : null , companies: this.downloadReportsForm.value?.companies };
    this.platformAdminService.generateReport(payload).pipe(takeUntil(this.observableSubscription$)).subscribe((response: any)=>{
      if(response?.status === 200 && response.data){
          const path = environment.docs + response.data.substring(23);
          this.bulkUploadDashboardService.downloadDocuments(path, response.data.substring(33));
      }
    });
  }

  breadCrumbNavigation() {
    this.router.navigate([`../../${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}`]);
  }

  getGlobalErrorMessages(error: string) {
    return errorUtils.errorMessages.get(error);
  }
  
  ngOnDestroy(): void {
    this.observableSubscription$.next();
    this.observableSubscription$.complete();
  }
}
