<div class="container-email">

<div class="col-md-6 float-left">
    <h1>All Emails</h1>
</div>

<div class="col-md-6  float-right text-right">
    <button class="btn btn-primary" (click)="composeEmail('new', null)">Compose</button>
</div>

<div class="clearfix"></div>
</div>

<div class="email-box">
    <div class="table-header">
        <div class="col-md-1 float-left"></div>

        <div class="col-md-11 float-right">
            <div class="col-md-3 float-left">From</div>
            <div class="col-md-3 float-left">To</div>
            <div class="col-md-4 float-left">Subject</div>
            <div class="col-md-2 float-left">Date</div>   
        </div>
    </div>


    <div class="table-body" *ngFor="let _data of inboxdata; let i = index"  [ngClass]="{'emailDrop': _data.dropdown == true}">

        <div (click)="changeClass(_data)" class="col-md-1 float-left arrow_down" *ngIf="_data?.emailsList?.length > 1"> 
            <i *ngIf="!_data.dropdown" class="bi bi-arrow-right-short"></i> 
            <i *ngIf="_data.dropdown" class="bi bi-arrow-down-short"></i>
        </div>
            
        <div class="col-md-11 float-right" style="padding-bottom: 15px;" *ngFor="let item of _data?.emailsList; let idx = i">
            <div class="col-lg-3 float-left fromuser" (click)="composeEmail('edit', item)">{{item.senderName}}</div>
            <div class="col-lg-3 float-left fromuser" (click)="composeEmail('edit', item)">{{item.recipientName}}</div>
            <div class="col-lg-4 float-left subjectline" (click)="composeEmail('edit', item)">{{item.subject}}</div>
            <div class="col-lg-2 float-left text-left">{{item.dateTime | date:'MM/dd/YYYY HH:mm'}}</div>
        </div>
    </div>


  
</div>