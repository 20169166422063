<p-card styleClass="tasklist-card task-deadline">
    <div class="card-header d-flex justify-content-between align-items-center pl-0 pr-0">
        Pending & Overdue        
    </div>
    <div class="pt-2 p-d-flex">
        <div class="col-md-12 pl-0 d-flex">
            <div class="py-3 1 col-6 pl-0 align-middle task-tab text-center"
                [ngClass]="{'activeTasksTab': activeTasksTab === 'dueSoon' }"
                (click)="onActiveTaskTab('dueSoon')"><span class="pl-3">Due Soon </span></div>
            <div class="py-3 col-6 pl-0 align-middle task-tab text-center"
                [ngClass]="{'activeTasksTab': activeTasksTab === 'overDue' }"
                (click)="onActiveTaskTab('overDue')"><span class="pl-3">Overdue</span></div>
        </div>
    </div>    
    <div class="content-holder" *ngIf="pendingAndDueTask.length > 0">
        <div class="search-results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollUpDistance]="1"
                [infiniteScrollThrottle]="300" [scrollWindow]="false"  (scrolled)="getPendingTasksList()">
        <div class="row" *ngIf="activeTasksTab === 'dueSoon'">            
                <ng-container *ngFor="let data of pendingAndDueTask">
                    <div *ngIf="data.overDue === false" class="task-container blue-bg">
                        <div class="title-text mb-2">
                            Task ID
                            <span *ngIf="data.superTaskId">{{ data.superTaskId }}</span>
                            <span *ngIf="data.superTaskId && (data.beneficiaryName || data.visaType)"> - </span>
                            <span *ngIf="data.beneficiaryName">{{ data.beneficiaryName }}</span>
                            <span *ngIf="data.beneficiaryName && data.visaType"> - </span>
                            <span *ngIf="data.visaType">{{ data.visaType }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <span class="title-text">{{data.noOfSteps}} Step</span> under this task have upcoming due dates.
                                Complete these steps immediately.
                            </div>
                            <div>
                                <button class="btn-task" (click)="goToTask(data)">Go to Task</button>
                                <button type="button" class="btn-ignore" (click)="ignoreTask(data)">Ignore</button>
                            </div>
                        </div>
                    </div>
                </ng-container>            
            <div class="no-data" *ngIf="pendingTaskLength === 0">
                <h6>No Data Found!</h6>
            </div>
        </div>
        <div class="row" *ngIf="activeTasksTab === 'overDue'">           
            <ng-container *ngFor="let data of pendingAndDueTask">
                <div *ngIf="data.overDue === true" class="task-container red-bg">
                    <div class="title-text mb-2">
                        Task ID 
                        <span *ngIf="data.superTaskId">{{ data.superTaskId }}</span>
                        <span *ngIf="data.superTaskId && (data.beneficiaryName || data.visaType)"> - </span>
                        <span *ngIf="data.beneficiaryName">{{ data.beneficiaryName }}</span>
                        <span *ngIf="data.beneficiaryName && data.visaType"> - </span>
                        <span *ngIf="data.visaType">{{ data.visaType }}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <span class="title-text">{{data.noOfSteps}} Step</span> under this task are overdue. Complete these steps immediately.
                        </div>
                        <div>
                            <button class="btn-task" (click)="goToTask(data)">Go to Task</button>
                            <button type="button" class="btn-ignore" (click)="ignoreTask(data)">Ignore</button>
                        </div>
                    </div>
                </div>           
            </ng-container>                      
            <div class="no-data" *ngIf="overDueTaskLength === 0">
                <h6>No Data Found!</h6>
            </div>
        </div>
        </div>
    </div>
</p-card>
