import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddTodoModalComponent } from '../add-todo-modal/add-todo-modal.component';
import { TodoListModalComponent } from '../todo-list-modal/todo-list-modal.component';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from "primeng/api";
import { CustomErrorToastComponent } from "custom-error-toast";
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-todo-checklist',
  templateUrl: './todo-checklist.component.html',
  styleUrls: ['./todo-checklist.component.scss']
})
export class TodoChecklistComponent implements OnInit {

  todoList: any = [];

  constructor(
    private dialog: MatDialog,
    private toastService: ToastrService,
    private confirmationService: ConfirmationService,
    private dashboardWidgetsService: DashboardWidgetsService,
  ) { }

  ngOnInit(): void {
    this.dashboardWidgetsService.modifyTodoItemObsvl$.subscribe(data => {
      if (data === true) {        
        this.loadTodoList();   
      }
    });
    this.loadTodoList();
  }

  openModal(): void {
    const dialogRef = this.dialog.open(AddTodoModalComponent, {
      width: '500px',
      hasBackdrop: false,
      disableClose: true,
    });
    
    dialogRef.afterClosed().subscribe((result) => {
      this.dashboardWidgetsService.setEditedTodoId(null);
    });   
  }

  openListModal(): void {
    this.dialog.open(TodoListModalComponent, {
      width: '500px', 
      hasBackdrop: false,
      disableClose: true, 
    });    
  }

  loadTodoList() {
    this.dashboardWidgetsService.getToDoList().subscribe(
      (result) => {
        this.todoList = result.data;        
      },
      (error) => {
        CustomErrorToastComponent.showErrorToast(this.toastService, error);
      }
    );
  }

  editTodoItem(item: any){    
    this.dashboardWidgetsService.setEditedTodoId(item);
    this.openModal();
  }

  onStatusChange(event: any){
    let status = event.target.value;    
      this.dashboardWidgetsService.getToDoListByStatus(status).subscribe(
        (result) => {
          this.todoList = result.data;          
        },
        (error) => {        
          CustomErrorToastComponent.showErrorToast(this.toastService, error);
        }
      );    
  }

  markCompleteToDo(item: any){
    this.dashboardWidgetsService.markCompleteToDoChecklist(item.id).subscribe(
      (result) => {                
        if (result.status === 200) {
          this.toastService.success(result.message, 'Success');
          this.dashboardWidgetsService.setModifyTodoItem(true);
        }        
      },
      (error) => {        
        CustomErrorToastComponent.showErrorToast(this.toastService, error);
      }
    );
  }

  deleteNote(item: any) {
    this.confirmationService.confirm({
      header: "Delete ToDo Item",
      icon: "pi pi-info-circle",
      rejectLabel: "Cancel",
      acceptLabel: "Yes, Delete it",
      acceptButtonStyleClass: "btn-accept-delete",
      rejectButtonStyleClass: "btn-cancel-delete",
      message: `<div class="mb-4"> Are you sure. you want to delete this to do list item? </div>
                <div class="mb-4">This action is irreversible, and the to do item  can not be recovered</div>
                <div class="mb-4">Are you sure you want to <span class="delete-text">delete?</span></div>`,
      accept: () => {
        this.dashboardWidgetsService.deleteTodoItemById(item.id).pipe(take(1))
        .subscribe({
          next: (result: any) => {
            if (result.status === 200) {
              this.toastService.success(result.message, 'Success');
              this.confirmationService.close();
              this.dashboardWidgetsService.setModifyTodoItem(true);
            }                                 
            
          },
          error: (err) => {            
            CustomErrorToastComponent.showErrorToast(this.toastService, err);
            this.confirmationService.close();
          },
        });
      },
      reject: () => {},
    });
  }

  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case 'COMPLETED':
        return 'status-completed';
      case 'UPCOMING':
        return 'status-upcoming';
      case 'OVERDUE':
        return 'status-overdue';     
      default:
        return 'status-upcoming'; // Default color
    }
  }

}
