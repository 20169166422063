import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as RolesRouteMap from '../../../../data/constants/roles';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import * as Task from 'src/app/modules/superTask/task-state/actions/task.actions';

@Component({
  selector: "app-task-deadline",
  templateUrl: "./task-deadline.component.html",
  styleUrls: ["./task-deadline.component.scss"]  
})
export class TaskDeadlineComponent implements OnInit {
  activeTasksTab = "dueSoon";  
  pendingAndDueTask: any[] = [];
  pendingTaskLength: number = 0;
  overDueTaskLength: number = 0;  
  page: number = 1;
  pageSize: number = 10;
  loading: boolean = false;

  constructor(
    private toastService: ToastrService,
    private router: Router,
    protected store: Store<any>,
    private dashboardWidgetsService: DashboardWidgetsService
  ) {}

  ngOnInit(): void {
    this.getPendingTasksList();    
  }

  onActiveTaskTab(activeTab: string) {
    this.activeTasksTab = activeTab;    
  }

  getPendingTasksList(): void {
    if (this.loading) return;

    this.loading = true;

    const payload = {
      pageNumber: this.page,
      pageSize: this.pageSize
    };

    this.dashboardWidgetsService.getPendingStepTasks(payload).subscribe((result: any) => {      
      this.pendingAndDueTask = [...this.pendingAndDueTask, ...result.data.taskDTO];
      this.overDueTaskLength = this.pendingAndDueTask.filter(task => task.overDue).length;
      this.pendingTaskLength = this.pendingAndDueTask.filter(task => !task.overDue).length;      
      this.page++;
      this.loading = false;
    });
  }

  ignoreTask(data: any){        
    this.dashboardWidgetsService.ignorePendingOverdueTask(data?.superTaskId).pipe(take(1))    
    .subscribe({
      next: (result: any) => {
        if (result.status === 200) {
          this.toastService.success(result.message);
        }     
        this.getPendingTasksList();
      },
      error: (err) => {            
        this.toastService.error(err);
      },
    });
  }

  goToTask(task: any){    
    if(task?.taskSource === 'WIZARD'){
      sessionStorage.setItem('wizardTID', task?.subTaskId);
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/immigration-wizard/upload/individual-details`]);
    }else{
      sessionStorage.setItem('tempBeneficiaryEmployeeId', task.beneficiaryId);
      sessionStorage.setItem('beneficiaryId', task.beneficiaryId);
      sessionStorage.removeItem('selectedSocCode'); 
      sessionStorage.removeItem('majorgroupValue');
      this.store.dispatch(new Task.SetTaskRouteId(task.superTaskId));
      this.router.navigate([`${RolesRouteMap.userRoleRouteMapping[sessionStorage.getItem('userTypeRole')]}/dashboard/superTask/task-details`]);
    }
  }
  
}
