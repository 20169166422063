import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from "primeng/api";
import { CustomErrorToastComponent } from "custom-error-toast";
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-add-todo-category-modal',
  templateUrl: './add-todo-category-modal.component.html',
  styleUrls: ['./add-todo-category-modal.component.scss']
})
export class AddTodoCategoryModalComponent implements OnInit {

  formData = { 
    id: null,  
    categoryName: '',
    color: '',    
  };

  isAddCategory: boolean = false;
  categoryList: any[] = [];

  constructor(
    private dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private dashboardWidgetsService: DashboardWidgetsService,
    private toastService: ToastrService,
    public dialogRef: MatDialogRef<AddTodoCategoryModalComponent>,
    
  ) { }

  ngOnInit(): void { 
    this.loadCategories();   
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  toggleCategory(){  
    this.clearForm();  
    this.isAddCategory = !this.isAddCategory;    
  }

  editCategory(item: any) { 
    if (!this.isAddCategory) {
      this.isAddCategory = true;
    } 
    this.formData = { ...item };
  }

  clearForm() {   
    this.formData = { id: null, categoryName: '', color: '' };
  }

  onSave(form: NgForm): void {
    if (form.valid) {              
      this.dashboardWidgetsService.saveToDoCategory(this.formData).subscribe({
        next: (result: any) => {
          if (result.status === 200) {
            this.toastService.success(result.message, 'Success');  
            this.dashboardWidgetsService.setModifyTodoItem(true);          
            this.loadCategories();
            this.clearForm();
          }            
        },
        error: (err) => {                      
          CustomErrorToastComponent.showErrorToast(this.toastService, err);
        },
      });      
    }
  }

  loadCategories(): void {
    this.dashboardWidgetsService.getToDoCategoryList().subscribe(
      (categories) => {
        this.categoryList = categories.data;
      },
      (error) => {        
        CustomErrorToastComponent.showErrorToast(this.toastService, error);
      }
    );
  } 

  deleteCategory(item: any) {
    this.confirmationService.confirm({
      header: "Delete Category",
      icon: "pi pi-info-circle",
      rejectLabel: "Cancel",
      acceptLabel: "Yes, Delete it",
      acceptButtonStyleClass: "btn-accept-delete",
      rejectButtonStyleClass: "btn-cancel-delete",
      message: `<div class="mb-4"> Are you sure. you want to delete this category? </div>
                <div class="mb-4">This action is irreversible, and the category can not be recovered</div>
                <div class="mb-4">Are you sure you want to <span class="delete-text">delete?</span></div>`,
      accept: () => {
        this.dashboardWidgetsService.deleteCategoryById(item.id).pipe(take(1))
        .subscribe({
          next: (result: any) => {
            if (result.status === 200) {
              this.toastService.success(result.message, 'Success');
              this.confirmationService.close();
            }                                 
            this.loadCategories();
          },
          error: (err) => {            
            CustomErrorToastComponent.showErrorToast(this.toastService, err);
            this.confirmationService.close();
          },
        });
      },
      reject: () => {
        this.confirmationService.close();
      },
    });
  }

}
