<div class="container-fluid padding-top-bottom-15 new-dashboard petitioner-dashboard">
  <!-- <div class="row margin-bottom-15">
    <div class="col-12 text-center">
      <dashboard-large-banner-ad></dashboard-large-banner-ad>
    </div>
  </div> -->
  <div class="row margin-bottom-15">
    <div class="col-12">
      <div class="row m-0">
        <div class="col-8">
          <span class="user-name">Welcome {{ userName }}!</span>
        </div>
        
        <!-- <<<<<<<<<<<<<<<<<<<<<<<<< email-client >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  -->
        
        <!-- <div class="col-4 align-self-center pr-0 div_align">
          <div>
            <div style="border: 1px solid #19A0DA" class="pull-right rounded p-0 ml-2 mail-align" >
          <span title="Go to My Mail" class="user-time  icn" routerLink="email-client">
            <mat-icon> mail_outline</mat-icon>
              <span class="pull-right calendar pl-1 pr-1">
                Mail</span>
          </span>
          </div>
        </div>
        </div> -->

        <!-- <<<<<<<<<<<<<<<<<<<<<<<<< calendar >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> -->
        <div class="col-4 d-flex justify-content-end align-items-center">
        <div class="align-self-center pr-0 ml-auto">
          <div>
            <div style="border: 1px solid #19A0DA" class="pull-left rounded p-1 mail-align" >
              <span title="Go to My Mail" class="user-time  icn" routerLink="email-client">
                <mat-icon> mail_outline</mat-icon>
                  <span class="pull-right calendar pl-1 pr-1">
                    Mail</span>
              </span>
              </div>

            <div style="border: 1px solid #19A0DA" class="pull-right rounded p-1 ml-2" >
          <span title="Go to My Calendar" class="user-time  icn" routerLink="scheduling" style="padding-left:10px !important;">
            <span class="material-icons pull-left">calendar_today</span>
              <span class="pull-right calendar" >
                Calendar</span>
          </span>
          </div>
        </div>
        </div>
        </div>
        
        <!-- <div class="col-6 align-self-center">
          <span class="user-time pull-right">
            <span class="material-icons pull-left">calendar_today</span><span>{{ userTimeNow }}</span>
            <a class="im-nav-link" routerLink="scheduling"><img src="/assets/images/Resources_icon.png" width="20px" alt="">
              Calendar</a>
          </span>
        </div> -->
           <!-- <div>
          <span class="user-time pull-right icn" routerLink="scheduling">
            <span class="material-icons pull-left">calendar_today</span>
            <span class="pull-right" >
              Calendar</span>
          </span>
        </div> -->   <!-- <span class="user-time pull-right">
            <span class="material-icons pull-left ">calendar_today</span><span>{{ userTimeNow }}</span>
          </span> -->

        <!-- <div class="col-6 align-self-center">
       
        <div  >
          <span title="Opens Calendar" class="user-time pull-right icn" routerLink="scheduling" style="padding-left:10px !important;">
            <span class="material-icons pull-left">calendar_today</span>
            <span class="pull-right"  >
              {{ userTimeNow }}</span>
          </span>
        </div>
       
            
        </div> -->
      </div>
    </div>
  </div>
  <div class="row margin-top-bottom-15">
    <div class="col-8">
      <task-list></task-list>
    </div>
    <div class="col-4">
      <notification-list></notification-list>
    </div>
  </div>
  <div class="row margin-top-bottom-15">
    <div class="col-8">
      <app-visa-bulletin></app-visa-bulletin>
    </div>
    <div class="col-4">
      <app-individuals-date-tracker class="w-100"></app-individuals-date-tracker>
    </div>    
  </div>
  <div class="row margin-top-15">
    <!-- <div class="col-4">
      <information-graph></information-graph>
    </div>
    <div class="col-4">
      <dashboard-team-members-list-preview></dashboard-team-members-list-preview>
    </div>
    <div class="col-4">
      <platform-statistics></platform-statistics>
    </div> -->
    <div class="col-4">
      <calendar-widget></calendar-widget>
    </div>
    <div class="col-4">
      <app-task-deadline></app-task-deadline>
    </div>  
    <div class="col-4">
      <app-todo-checklist></app-todo-checklist>
    </div>   
  </div>
</div>
