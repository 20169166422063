<div class="modal-content category-dialog">
    <div class="modal-header">        
        <h5 class="modal-title">Category Settings</h5>
        <button type="button" class="btn btn-link p-0" (click)="closeDialog()">
            <i class="fa fa-times"></i>
        </button>               
    </div>
    <div class="modal-body">
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <button [disabled]="isAddCategory" class="btn btn-outline-primary btn-add-new" (click)="toggleCategory()">
                    <i class="fa fa-plus-circle mr-1"></i> Create New Category
                </button>
            </div>
            <div>
                <small class="text-secondary">
                    <i class="fa fa-flag mr-1 text-warning"></i> 
                    Represents Custom Categories
                </small>
            </div>
        </div>
        <div *ngIf="isAddCategory">
            <form class="mt-4" id="categoryForm" #categoryForm="ngForm">
                <div class="form-row mb-3">
                    <div class="col">
                        <label>Enter Category Name</label>
                        <input type="text" class="form-control" [(ngModel)]="formData.categoryName" name="categoryName"
                            #categoryName="ngModel" required />
                    </div>
                    <div class="col">
                        <label>Set Color</label>
                        <input type="color" class="form-control" [(ngModel)]="formData.color" name="color" #color="ngModel" required>
                    </div>
                </div>
                <div class="form-row mb-3">
                    <div class="col">
                        <button type="button" class="btn btn-primary save-btn mr-3" [disabled]="!categoryForm.valid"
                            (click)="onSave(categoryForm)">
                            SAVE
                        </button>
                        <button type="button" class="btn btn-secondary cancel-btn" (click)="toggleCategory()">
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="list mt-4" *ngIf="categoryList.length > 0">
            <h5>Categories List</h5>
            <hr />
            <ng-container *ngFor="let item of categoryList">
                <div class="d-flex align-items-center justify-content-between border-bottom"  [ngClass]="{'py-2': item.isDefault}">
                    <div>
                        <h6>{{item.categoryName}} 
                            <i *ngIf="!item.isDefault" class="fa fa-flag ml-1 text-warning"></i>
                        </h6>
                    </div>
                    <div *ngIf="!item.isDefault">
                        <button type="button" class="btn btn-link p-0" matTooltip="Edit" (click)="editCategory(item)">
                            <i class="fa fa-pencil"></i>
                        </button> 
                        <button type="button" class="btn btn-link p-0" matTooltip="Delete" (click)="deleteCategory(item)">
                            <i class="fa fa-trash"></i>
                        </button> 
                    </div>                    
                </div>
            </ng-container>
        </div> 
    </div>
</div>


<!-- Confirm Delete Modal -->
<p-confirmDialog id="delete-category-modal" [style]="{width: '35vw'}"></p-confirmDialog>

  