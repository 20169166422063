import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BeneficiaryListComponent } from './beneficiary-list/beneficiary-list.component';
import { PlatformAdminGuardService } from './platform-admin-guard.service';
import { PlatformAdminLandingComponent } from './platform-admin-landing/platform-admin-landing.component';
import { DownloadReportsComponent } from './download-reports/download-reports.component';

const routes: Routes = [
  {
    path: 'platform-admin-landing',
    component: PlatformAdminLandingComponent,
    children: [
      {
        path: '',
        redirectTo:'dashboard',
        pathMatch:'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard-platform-admin/dashboard-platform-admin.module').then((mod) => mod.PlatformAdminDashboardModule),
      },
      {
        path: 'companies',
        loadChildren: () => import('../petitioner/petitioner.module').then((mod) => mod.PetitionerModule),
      },
      {
        path: 'attorneys',
        loadChildren: () => import('../attorney/attorney.module').then((mod) => mod.AttorneyModule),
      },
      {
        path: 'users',
        loadChildren: () => import('../user/user.module').then((mod) => mod.UserModule),
      },
      {
        path: 'roles',
        loadChildren: () => import('../roles/roles.module').then((mod) => mod.RolesModule),
      },
      {
        path: 'beneficiaries',
        loadChildren: () => import('../beneficiary/beneficiary.module').then((mod) => mod.BeneficiaryModule),
      },
      {
        path: 'petitions',
        loadChildren: () => import('../manage-petitions/manage-petitions.module').then((mod) => mod.ManagePetitionsModule),
      },
      {
        path: 'companies',
        loadChildren: () => import('../petitioner/petitioner.module').then((mod) => mod.PetitionerModule),
      },
      {
        path: 'policies',
        loadChildren: () => import('../policies/policies.module').then((mod) => mod.PoliciesModule),
      },
      {
        path: 'task-allocation',
        loadChildren: () => import('../task-allocation/task-allocation.module').then((mod) => mod.TaskAllocationModule),
      },
      {
          path: 'notifications',
          pathMatch: 'full',
          loadChildren: () => import('../../modules/notifications/notifications.module').then((mod) => mod.NotificationsModule)
      },

      {
        path: 'beneficiary-list',
        component: BeneficiaryListComponent
      },
      {
        path: 'help',
        loadChildren: () => import('../../modules/help/help.module').then(m => m.HelpModule),
        data: { preload: true }
      },
      {
        path: 'visa-designer',
        loadChildren: () => import('../../modules/visa-designer/visa-designer.module').then(m => m.VisaDesignerModule),
        data: { preload: true }
      },{
        path: 'free-trial',
        loadChildren: () => import('../../modules/free-trial/freetrial.module').then(m => m.FreeTrialModule),
        data: { preload: true }
      },
      {
        path: 'data-migration',
        loadChildren: () => import('../../modules/data-migration/data-migration.module').then((mod) => mod.DataMigrationModule),
      },
      {
        path: 'page-designer',
        loadChildren: () => import('../form-designer/form-designer.module').then((mod) => mod.FormDesignerModule),
      },
      {
        path: 'master-data',
        loadChildren: () => import('../../modules/master-data/master-data.module').then((mod) => mod.MasterDataModule),
      },
      {
        path: 'download-reports',
        component: DownloadReportsComponent
      }
    ],
    canActivate: [PlatformAdminGuardService],
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class PlatformAdminLandingRoutingModule { }
