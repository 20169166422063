<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" id="add-checklist-form" class="modal-content checklist-dialog">
  <form #addChecklistForm="ngForm">
    <div class="modal-header" cdkDragHandle>
      <button class="btn btn-link p-0" matTooltip="Duplicate" (click)="openDuplicateModal()">
        <i class="fa fa-plus"></i>
      </button>
      <button type="button" class="edit-btn ml-auto mb-2" (click)="enableEdit()" *ngIf="isEdit">
        <i class="fa fa-pencil"></i> EDIT
      </button>
      <button type="button" class="btn btn-link" matTooltip="Close" (click)="closeDialog()">
        <i class="fa fa-times"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="form-row mb-3">
        <div class="col">
          <textarea class="form-control custom-textarea" placeholder="Heading goes here" name="heading"
            [(ngModel)]="formData.heading" [disabled]="!isEditOn" required>
          </textarea>
        </div>
      </div>

      <div class="form-row mb-3">
        <div class="col">
          <div class="input-group">
            <textarea class="form-control custom-textarea" placeholder="Description goes here" name="description"
              [(ngModel)]="formData.description" [disabled]="!isEditOn" required>
            </textarea>
            <span class="input-group-append">
              <div class="input-group-text custom-textarea">
                <button class="btn btn-link p-0 mice" (mousedown)="startRecording()" (mouseup)="stopRecording()">
                  <i class="fa fa-microphone"></i>
                </button>               
              </div>
            </span>
          </div>
        </div>
      </div>

      <div class="form-row mb-3">
        <div class="col">
          <label>Priority</label>
          <select class="custom-select" name="priorityCode" [(ngModel)]="formData.priorityCode" 
            [disabled]="!isEditOn" required>
            <option value="" selected>Select</option>
            <option value="LOW">Low</option>
            <option value="MEDIUM">Medium</option>
            <option value="HIGH">High</option>            
          </select>
        </div>

        <div class="col">
          <label>Category</label>
          <select class="custom-select" name="categoryId" [(ngModel)]="formData.categoryId"
            (change)="onCategoryChange($event)" [disabled]="!isEditOn" required>
            <option value="" selected>Select Category</option>
            <option *ngFor="let category of categoryList" [value]="category.id">
              {{ category.categoryName }}
            </option>
          </select>
        </div>

        <div class="col">
          <label>Color</label>         
          <input type="color" class="form-control" [(ngModel)]="formData.color" name="color" #color="ngModel" disabled [disabled]="!isEditOn" required>
        </div>
      </div>

      <div class="form-row mb-3">
        <div class="col-4">
          <label>Date</label>
          <input type="date" class="form-control" name="toDoDate" [(ngModel)]="formData.toDoDate"  [min]="today"  [disabled]="!isEditOn" required>
        </div>

        <div class="col-4">
          <label>Time</label>
          <input type="time" class="form-control" name="time" [(ngModel)]="formData.time" [disabled]="!isEditOn" required>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="save-btn" (click)="onSave(addChecklistForm)" *ngIf="!isEdit"
        [disabled]="addChecklistForm.invalid">
        SAVE
      </button>
      <button type="button" class="save-btn" (click)="markCompleteToDo()" *ngIf="isEdit" 
        [disabled]="markCompleted" >
        Mark Complete
      </button>
      <button type="button" class="delete-btn" (click)="deleteNote()" *ngIf="isEdit">
        <i class="fa fa-trash-o"></i> Delete         
      </button>
    </div>
  </form>

</div>