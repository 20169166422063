<div id="todoList" class="modal-content">
    <div class="modal-header">
        <div class="ml-auto">
            <button type="button" class="btn btn-link p-0" matTooltip="Settings" (click)="openCategoryModal()">
                <i class="fa fa-cog"></i>
            </button>
            <button type="button" class="btn btn-link p-0" matTooltip="Close" (click)="closeDialog()">
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">

        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <h5 class="font-weight-bold mb-0">Checklist</h5>
                <select class="custom-select ml-2 filter-dropdown" (change)="onStatusChange($event)">
                    <option value="" selected>All</option>
                    <option value="UPCOMING">Upcoming</option>
                    <option value="COMPLETED">Completed</option>
                </select>
            </div>
            <button class="btn btn-outline-primary btn-add-new" (click)="openModal()">
                <i class="fa fa-plus-circle mr-1"></i> Add New
            </button>
        </div>
        
        <div class="p-2 my-2 bg-light d-flex align-items-center justify-content-between">
            <div>
                <div class="search-container">
                    <input type="text" class="search-input" placeholder="Search by keywords"
                        [(ngModel)]="searchQuery" (input)="filterTodoList()" />
                    <button class="search-btn">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
            </div>
            <div>
                <button type="text" class="todoFilter-btn" pButton label="Filter"
                    (click)="checklistFilterPanel.toggle($event)" icon="pi pi-filter" iconPos="right"> 
                </button>
                <div class="filter">
                    <p-overlayPanel #checklistFilterPanel [dismissable]="false">
                        <div class="form-row mb-3">
                            <div class="col-12">                               
                                <select class="custom-select" name="categoryId" [(ngModel)]="formData.categoryId"
                                    (change)="onCategoryChange($event)" required>
                                    <option value="" selected>Select Category</option>
                                    <option *ngFor="let category of categoryList" [value]="category.id">
                                        {{ category.categoryName }}
                                    </option>
                                </select>
                            </div>                            
                        </div>
                        <div class="form-row mb-3">
                            <div class="col-12">                                                               
                                <input type="color" class="form-control" [(ngModel)]="formData.color" name="color" #color="ngModel" disabled required>
                            </div>
                        </div>
                        <div class="form-row mb-3">
                            <button type="button" class="btn btn-primary btn-block text-white" [disabled]="!formData.categoryId"
                                (click)="filterByCategory(checklistFilterPanel);">
                                Apply
                            </button>
                        </div>
                    </p-overlayPanel>
                </div>
            </div>
        </div>

        <div class="list-item-container">
            <ng-container *ngIf="todoList.length > 0">
                <ng-container *ngFor="let item of todoList">            
                    <div class="card list-item" [ngStyle]="{'backgroundColor': item.categoryColor }">
                        <div class="row no-gutters align-items-center p-2">
                            <!-- Date Section -->
                            <div class="col-auto date-section text-center">
                                <div class="date-day">{{item.toDoDate | date: 'dd' }}</div>
                                <div class="date-month">{{item.toDoDate | date: 'MMM' }}</div>
                                <div class="date-time">{{item.time}}</div>
                            </div>
        
                            <!-- Content Section -->
                            <div class="col ml-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <!-- Task Title -->
                                    <div class="title-text" (click)="editTodoItem(item)">
                                        {{item.heading}}
                                    </div>
                                    <!-- Three Dot Menu -->
                                    <div class="menu-container">
                                        <mat-icon matTooltip="settings" class="mx-2" [matMenuTriggerFor]="menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </mat-icon>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item [disabled]="item.statusDto?.code === 'COMPLETED'" (click)="markCompleteToDo(item)">Mark Complete</button>
                                            <button mat-menu-item (click)="deleteNote(item)">Delete Note</button>
                                        </mat-menu>
                                    </div>
                                </div>
                                <!-- Category and Status -->
                                <div class="text-muted small">
                                    {{item.categoryName}}
                                    <span class="badge status-badge" [ngClass]="getStatusClass(item.statusDto?.code)">{{item.statusDto?.name}}</span>
                                </div>
                            </div>
                            <!-- Priority Dropdown -->
                            <div class="col-auto">
                                <select class="form-control">
                                    <option selected>{{item.priorityDto.name}}</option>                       
                                </select>
                            </div>
                        </div>
                    </div>            
                </ng-container>
            </ng-container>
            <div class="no-data" *ngIf="todoList.length === 0">
                <h6>No Data Found!</h6>
            </div>
        </div>
    </div>    
</div>

<!-- Confirm Delete Modal -->
<p-confirmDialog id="delete-list-todo-modal" [style]="{width: '25vw'}"></p-confirmDialog>