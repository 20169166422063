import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ImagilityBaseResponse } from '../data/models/response.model';


@Injectable({
  providedIn: 'root'
})
export class PlatformAdminService {

  constructor(private httpClient: HttpClient) { }

  generateReport(payload: any) {
    return this.httpClient.post(`/admin/generate/userreport`, payload).pipe(
      map((response: ImagilityBaseResponse) => response)
    );
  }
}