import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { DashboardWidgetsService } from 'src/app/services/dashboard-widgets.service';
import { ToastrService } from 'ngx-toastr';
import { CustomErrorToastComponent } from "custom-error-toast";
import { ReferenceLibraryService } from 'reference-library';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';

@Component({
  selector: 'app-add-todo-modal',
  templateUrl: './add-todo-modal.component.html',
  styleUrls: ['./add-todo-modal.component.scss']
})
export class AddTodoModalComponent implements OnInit {

  formData = {
    id: null,
    heading: '',
    description: '',    
    categoryId: '',
    priorityCode: '',
    color: '',
    toDoDate: '',
    time: ''
  };  

  static modalOffset = 80;
  categoryList: any[] = [];
  isEdit: boolean = false;
  isEditOn: boolean = false;  
  markCompleted: boolean = false;
  today: string = '';

  constructor(
    private dialog: MatDialog,
    private dashboardWidgetsService: DashboardWidgetsService,
    public voiceRecognitionService: VoiceRecognitionService,
    private referenceLibraryService: ReferenceLibraryService,
    private toastService: ToastrService,
    public dialogRef: MatDialogRef<AddTodoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { offset: number }

  ) { }

  ngOnInit(): void {    
    this.dashboardWidgetsService.editTodoItemObsvl$.subscribe(data => {
      if (data) {                
        this.populateFormData(data);  
        this.isEdit = true;          
      }else{
        this.isEditOn = true;
        this.setCurrentDateAndTime();
      }
    });
    this.voiceRecognitionService.init();    
    this.loadCategories();    
  }

  startRecording() {
    this.voiceRecognitionService.start();
  }

  stopRecording() {
    this.voiceRecognitionService.stop();
    this.formData.description += this.voiceRecognitionService.text;
    this.voiceRecognitionService.text = ''; // Clear the recognized text after appending to message
  }

  submitMessage(event: Event) {
    // Handle message submission logic here    
    this.formData.description = ''; // Clear the input after submission
  }

  populateFormData(response: any): void {    
    this.formData = {
      id: response.id || null,
      heading: response.heading || '',
      description: response.description || '',
      categoryId: response.categoryId || '',
      priorityCode: response.priorityDto?.code || '',
      color: response.categoryColor || '',
      toDoDate: this.formatDate(response.toDoDate) || '',
      time: response.time || ''
    };
    //DIsable button if already mark completed
    this.markCompleted = response.statusDto?.code === 'COMPLETED';
  }

  closeDialog(): void {
    this.dialogRef.close(this.isEdit);    
  }

  openDuplicateModal(): void {
    // Calculate the new offset for the next modal
    const nextOffset = (this.data?.offset || 0) + AddTodoModalComponent.modalOffset;
    
    this.dialog.open(AddTodoModalComponent, {
      width: '500px', 
      hasBackdrop: false,
      disableClose: true,
      data: { offset: nextOffset },
      position: {
        top: `${nextOffset}px`,
        left: `${nextOffset}px`
      }
    });
  }

  formatDate(dateString: string): string {
    if (dateString) {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // Extracts the date in 'YYYY-MM-DD' format
    }
    return '';
  }

  setCurrentDateAndTime() {
    const now = new Date();
    // disable past dates
    this.today = now.toISOString().split('T')[0];
    //set current date and time
    this.formData.toDoDate = now.toISOString().slice(0, 10);    
    this.formData.time = now.toTimeString().slice(0, 5);
  }

  loadCategories() {
    this.dashboardWidgetsService.getToDoCategoryList().subscribe(
      (categories) => {
        this.categoryList = categories.data;        
      },
      (error) => {
        this.toastService.error(error);
      }
    );
  }

  onCategoryChange(event: any) {
    const selectedCategoryId = event.target.value;
    const selectedCategory = this.categoryList.find(category => category.id === +selectedCategoryId);
  
    if (selectedCategory) {        
      this.formData.categoryId = selectedCategory.id; 
      this.formData.color = selectedCategory.color;
    }
  }

  enableEdit(){
    this.isEditOn = true;
    this.isEdit = false;
  }
  
  clearForm() {   
    this.formData = {  
      id: null,
      heading: '',
      description: '',    
      categoryId: '',
      priorityCode: '',
      color: '',
      toDoDate: '',
      time: ''
    };
  }

  onSave(form: NgForm) {
    if (form.valid) {       
      this.dashboardWidgetsService.saveToDoChecklist(this.formData).subscribe({
        next: (result: any) => {
          if (result.status === 200) {
            this.toastService.success(result.message); 
            this.dashboardWidgetsService.setModifyTodoItem(true); 
            this.clearForm();                                  
          }            
        },
        error: (err) => {            
          this.toastService.error(err);
        },
      });  
      this.closeDialog();
    }
  }

  deleteNote() {
    let noteId = this.formData.id;
    this.dashboardWidgetsService.deleteTodoItemById(noteId).subscribe(
      (result: any) => {
        if (result.status === 200) {
          this.toastService.success(result.message, 'Success');
          this.dashboardWidgetsService.setModifyTodoItem(true);
          this.closeDialog();
        }
      },
      (error) => {        
        CustomErrorToastComponent.showErrorToast(this.toastService, error);
      }
    );
  }
  
  markCompleteToDo() {
    let noteId = this.formData.id;
    this.dashboardWidgetsService.markCompleteToDoChecklist(noteId).subscribe(
      (result: any) => {
        if (result.status === 200) {
          this.toastService.success(result.message, 'Success');
          this.dashboardWidgetsService.setModifyTodoItem(true);
          this.closeDialog();
        }
      },
      (error) => {        
        CustomErrorToastComponent.showErrorToast(this.toastService, error);
      }
    );
  }

}
