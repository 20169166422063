import { Component, OnInit } from '@angular/core';
import { ImagilityComposeEmailComponent } from '../../email-client/unified-email-component/imagility-compose-email/imagility-compose-email.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttorneyProfileService } from '../../../services/attorney-profile.service';
@Component({
  selector: 'app-interaction-email',
  templateUrl: './interaction-email.component.html',
  styleUrls: ['./interaction-email.component.scss']
})
export class InteractionEmailComponent implements OnInit {
  companyId = sessionStorage.getItem('companyId');
  taskId = sessionStorage.getItem('taskID');
  userId = sessionStorage.getItem('userId');
  inboxdata:any = [];
  emailDropdown:boolean = false;


  constructor(private modalService: NgbModal, private attorService: AttorneyProfileService) { }

  ngOnInit(): void {
    
    this.getUnifiedEmail();
  }

  changeClass(_data){
    // 
    _data.dropdown =! _data.dropdown;
    this.emailDropdown =! this.emailDropdown;
  }

  composeEmail(mode, data){
   let modalRef = this.modalService.open(ImagilityComposeEmailComponent, {
      size:'xl',
      backdrop:'static' 
    })

    if(mode == 'edit'){
      modalRef.componentInstance.emailData = data;
      modalRef.componentInstance.currentSelected = data.folder;
    }
    else{
      modalRef.componentInstance.emailData = null;
      modalRef.componentInstance.currentSelected = 'Compose';
    }

    


    modalRef.componentInstance.unifiedTaskId = sessionStorage.getItem('taskID');
    modalRef.componentInstance.isUnified = true;

    modalRef.result.then(res => {
      
      if(res.data != null){ 
          this.getUnifiedEmail();
      }
    })
  }


  getUnifiedEmail(){
    
    let body = {
      "pageNumber": 1,
      "pageSize": 10,
      "searchText": ""
    }
    this.attorService.getUnifiedInteractionEmail(Number(this.taskId), body).subscribe(result => {
      
      this.inboxdata = result.data.emails;
    
      

    }, (error:any) => {
      this.inboxdata = [];
    })
  }

}
